<template>
    <div>
        <grid-header :grid-api="gridApi">
            Service level: {{serviceLevelData ? serviceLevelData.serviceLevelName : '...'}}
            <template slot="actions">
                <mercur-button
                    @click="openAddServiceLevelPopup"
                    class="btn btn-raised btn-yellow text-uppercase"
                    data-test="addNewServiceLevelConfigurationButton"
                    v-if="isAllowedTo('createServiceLevelConfiguration')">
                    <i class="fas fa-plus"></i>
                    Add configuration
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            ref="grid"
            :options="options"
            :url="url"
            v-if="isAllowedTo('getServiceLevelsByCarrierId')"
        />
        <p v-else class="permission-message">Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="addServiceLevelConfigurationPopupOpen">
            <div slot="header"><h3>Add service level <span v-if="carrierData">for {{carrierData.carrierName}}</span></h3></div>

            <form @submit.prevent="submitAddServiceLevel">
                <div slot="default">
                    <mercur-input
                        v-model.trim="$v.addServiceLevelConfigurationParams.countryFrom.$model"
                        :disabled="addServiceLevelConfigurationLoading"
                        data-test="addServiceLevelConfigurationParamsCountryFrom"
                        required
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelConfigurationParams.countryFrom.$error}">
                        Country from
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelConfigurationParams.countryFrom.$invalid">Required</span>
                        </template>
                    </mercur-input>
                    <mercur-input
                        v-model.trim="$v.addServiceLevelConfigurationParams.countryTo.$model"
                        :disabled="addServiceLevelConfigurationLoading"
                        data-test="addServiceLevelConfigurationParamsCountryTo"
                        required
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelConfigurationParams.countryTo.$error}">
                        Country to
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelConfigurationParams.countryTo.$invalid">Required</span>
                        </template>
                    </mercur-input>
                    <mercur-select
                        v-model.trim.number="$v.addServiceLevelConfigurationParams.currency.$model"
                        :disabled="addServiceLevelConfigurationLoading"
                        data-test="addServiceLevelConfigurationParamsCurrencySelect"
                        v-on:keypress.enter.prevent
                        required>
                        <template slot="label">Currency</template>
                        <option
                            v-for="(currency, key) in currencies"
                            :key="key"
                            :value="currency.value">
                            {{currency.sign}} ({{currency.title}})
                        </option>
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelConfigurationParams.currency.$invalid">Required</span>
                        </template>
                    </mercur-select>
                    <mercur-input
                        v-model.number="$v.addServiceLevelConfigurationParams.transitDays.$model"
                        :disabled="addServiceLevelConfigurationLoading"
                        data-test="addServiceLevelConfigurationParamsTransitDays"
                        required
                        type="number"
                        ref="inputfield"
                        :class="{'form-invalid': $v.addServiceLevelConfigurationParams.transitDays.$error}">
                        Transit days
                        <template slot="note">
                            <span class="form-error" v-if="$v.addServiceLevelConfigurationParams.transitDays.$invalid">Required</span>
                        </template>
                    </mercur-input>
                    <mercur-textarea v-model="addServiceLevelConfigurationParams.configuration" required>
                        Configuration
                    </mercur-textarea>
                </div>

                <div slot="footer" class="mt-4">
                    <mercur-button class="btn btn-raised text-uppercase" @click="addServiceLevelConfigurationPopupOpen = false">Close</mercur-button>
                    <mercur-button class="btn btn-raised btn-yellow text-uppercase" data-test="addServiceLevelConfigurationSaveButton" :disabled="addServiceLevelConfigurationLoading || $v.addServiceLevelConfigurationParams.$invalid" type="submit">Save</mercur-button>
                </div>
            </form>
            <mercur-progress-bar indeterminate v-if="addServiceLevelConfigurationLoading" />
        </mercur-dialog>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '../../components/DataTable'
import { required } from 'vuelidate/lib/validators'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'CarrierServiceLevelConfigurationList',
    components: { GridHeader, DataTable },
    props: ['carrierId', 'carrierData', 'serviceLevelId', 'serviceLevelData'],
    data () {
        return {
            url: CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.CONFIGURATIONS.OVERVIEW.replace('{carrierId}', this.carrierId).replace('{serviceLevelId}', this.serviceLevelId),
            gridApi: null,
            addServiceLevelConfigurationPopupOpen: false,
            addServiceLevelConfigurationLoading: false,
            addServiceLevelConfigurationParams: {},
            currencies: CONFIG.CURRENCIES,
        }
    },

    validations: {
        addServiceLevelConfigurationParams: {
            countryFrom: {
                required,
            },
            countryTo: {
                required,
            },
            transitDays: {
                required,
            },
            currency: {
                required,
            },
        },
    },

    methods: {
        openAddServiceLevelPopup () {
            this.$v.$reset()
            this.addServiceLevelConfigurationParams = {
                countryFrom: '',
                countryTo: '',
                transitDays: null,
                configuration: '{}',
                currency: null,
            }
            this.addServiceLevelConfigurationPopupOpen = true
        },
        async submitAddServiceLevel () {
            if (!this.isAllowedTo('createServiceLevelConfiguration')) {
                return
            }

            this.addServiceLevelConfigurationLoading = true

            const params = {
                ...this.addServiceLevelConfigurationParams,
                configuration: JSON.parse(this.addServiceLevelConfigurationParams.configuration),
            }
            const jobName = JSON.stringify(params)
            const url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.CONFIGURATIONS.ADD
                .replace('{carrierId}', this.$route.params.carrierId)
                .replace('{serviceLevelId}', this.$route.params.serviceLevelId)
            this.addJob(jobName)

            try {
                await this.$api.post(url, params)

                this.$root.$emit('notification:global', {
                    message: `Service level configuration was added`,
                })
                this.addServiceLevelConfigurationPopupOpen = false
                this.$refs.grid.refreshGrid()
            } catch (error) {
                const errorMessage = `Adding service level failed. Please try again.`
                const errors = error.data ? { data: error.data } : error
                this.$root.$emit('notification:global', {
                    message: errorMessage || error.message || null,
                    type: 'error',
                    errors: errors,
                })
            } finally {
                this.addServiceLevelConfigurationLoading = false
                this.finishJob(jobName)
            }
        },
    },

    beforeMount () {
        this.options = {
            columns: {
                'Configuration Id': {
                    field: 'serviceLevelConfigurationId',
                    colId: 'serviceLevelConfigurationId',
                    width: 320,
                    cellRendererFramework: 'RouterlinkCell',
                    cellRendererParams: (params) => {
                        return {
                            routerlink: {
                                to: {
                                    name: 'CarrierServiceLevelConfigurationView',
                                    params: {
                                        ...this.$route.params,
                                        serviceLevelConfigurationId: params.data.serviceLevelConfigurationId,
                                    },
                                },
                            },
                        }
                    },
                },
                'From': {
                    field: 'countryFrom',
                    width: 120,
                },
                'To': {
                    field: 'countryTo',
                    width: 120,
                },
                'Transit days': {
                    field: 'transitDays',
                    width: 120,
                },
                'Currency': {
                    field: 'currency',
                    width: 120,
                },
                'Created': {
                    field: 'dateCreated',
                    width: 150,
                    valueFormatter: ({ value }) => {
                        return this.$options.filters.asDatetime(value)
                    },
                },
                'Updated': {
                    field: 'dateUpdated',
                    width: 150,
                    valueFormatter: ({ value }) => {
                        return this.$options.filters.asDatetime(value)
                    },
                },
            },
            actions: [
                {
                    text: 'Edit',
                    icon: 'fas fa-edit',
                    to: (params) => {
                        return {
                            name: 'CarrierServiceLevelConfigurationView',
                            params: {
                                ...this.$route.params,
                                serviceLevelConfigurationId: params.data.serviceLevelConfigurationId,
                            },
                        }
                    },
                    attr: {
                        'data-test': 'carrierServiceLevelConfigurationListEdit',
                    },
                },
            ],
        }
    },
}
</script>

<style lang="scss" scoped>
    .ag-grid {
        height: 500px;
    }
</style>
