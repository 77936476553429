<template>
    <mercur-card class="full-height-layout fill">
        <carrier-service-level-configuration-list
            class="fill full-height-layout"
            :carrier-id="$route.params.carrierId"
            :service-level-id="$route.params.serviceLevelId"
            :service-level-data="serviceLevelData"
        ></carrier-service-level-configuration-list>
    </mercur-card>
</template>

<script>
import CarrierServiceLevelConfigurationList from '@/components/carriers/CarrierServiceLevelConfigurationList'

export default {
    name: 'CarrierServiceLevelView',
    components: { CarrierServiceLevelConfigurationList },
    props: ['carrierData', 'serviceLevelData'],
}
</script>
